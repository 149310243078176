/**
 * This module provides a centralized way to manage feature flags in the
 * application. There flags allow developmental features to be turned on at
 * runtime without code changes. Flags can be controlled via URL parameters and
 * are stored in session storage for persistence across page reloads.
 *
 * The module reads feature flags on application load and caches them. Flags
 * cannot be enabled during SSR or in the production instance of the app.
 *
 * @module featureFlags
 *
 * @example
 * // to implement a new feature flag add a key to the `FEATURE_FLAGS_KEYS`
 * // constant, this value will serve as the id for the feature in the url
 * // param as well as its key in the feature flag object in the code
 * const FEATURE_FLAG_KEYS = [
 *   // ...existing flags,
 *   'newFeature'
 * ]
 *
 * // in the module(s) where you need the flag
 * import featureFlags from '#/shared/featureFlags'
 *
 * // will be `true` or `false` depending upon whether the feature is turned on
 * console.log(featureFlags.newFeature)
 *
 * // turn the feature on with a url like this:
 * // `<app-path>?featureFlags=newFeature`
 *
 * // comma separate feature IDs to turn on more than one:
 * // `<app-path>?featureFlags=feat1,feat2`
 */

import { PRODUCTION_HOSTNAME } from '#/shared/constants';
import { getItem, storeItem } from '#/shared/utils/storage';

const FEATURE_FLAG_KEYS = ['mapLibre', 'mapCompass'];
const FEATURE_FLAGS_STORAGE_KEY = 'featureFlags';

const getFeatureFlags = () => {
  let featureLookup: Record<string, boolean> = Object.fromEntries(
    FEATURE_FLAG_KEYS.map(f => [f, false])
  );

  // feature flags cannot be turned on during SSR because they use browser
  // objects and are disabled in the production instance of the app
  if (
    typeof window === 'undefined' ||
    window.location.hostname === PRODUCTION_HOSTNAME
  )
    return featureLookup;

  const { location, history } = window;
  const urlParams = new URLSearchParams(location.search);
  const urlFeatureFlags =
    urlParams
      .get(FEATURE_FLAGS_STORAGE_KEY)
      ?.split(',')
      .filter(f => FEATURE_FLAG_KEYS.includes(f)) || [];

  const storedFeatureLookup = getItem(
    FEATURE_FLAGS_STORAGE_KEY,
    {},
    { session: true }
  );

  // features will be turned on if there is a flag for it in the url
  // or if it's set to `true` in session storage
  featureLookup = {
    ...featureLookup,
    ...storedFeatureLookup,
    ...Object.fromEntries(urlFeatureFlags.map(f => [f, true])),
  };

  if (urlFeatureFlags.length) {
    // store flags from  url in session storage so that they persist
    // when a browser tab is refreshed
    storeItem(FEATURE_FLAGS_STORAGE_KEY, featureLookup, { session: true });

    // remove the flags param from the url after it's read
    urlParams.delete(FEATURE_FLAGS_STORAGE_KEY);
    const paramsAsString = urlParams.toString();
    history.replaceState(
      null,
      '',
      location.pathname + (paramsAsString ? `?${paramsAsString}` : '')
    );
  }

  return featureLookup;
};

// flags are read on application load and remain cached thereafter
const featureFlags = getFeatureFlags();
export default featureFlags;
