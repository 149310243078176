import { createSlice } from '@reduxjs/toolkit';
import {
  FAVORITE_STOPS_STORAGE_KEY,
  TRACKED_VEHICLE_CENTER_MAP_STORAGE_KEY,
} from '#/transit-tracker/constants';
import { getItem } from '#/shared/utils/storage';

const initialState = {
  focusedOnHighlightedStop: false,
  highlightedStop: null,
  arrivalsTimestamp: null,
  nearbyArrivals: null,
  nearbyLocationArrivals: null,
  vehicleRoute: null,
  vehicleRouteTimestamp: null,
  vehicleRouteError: false,
  vehicleRouteLoading: false,
  favoriteArrivals: null,
  favoriteStops: getItem(FAVORITE_STOPS_STORAGE_KEY, []),
  trackedVehicleCenterMap: getItem(
    TRACKED_VEHICLE_CENTER_MAP_STORAGE_KEY,
    true
  ),
  alertActive: false,
  trackedVehicle: null,
};

const transitTrackerSlice = createSlice({
  name: 'transitTracker',
  initialState,
  reducers: {
    trackedVehicleCenterMap(state, action) {
      state.trackedVehicleCenterMap = action.payload;
    },
    fetchArrivalsSuccess(state, action) {
      return {
        ...state,
        nearbyArrivals: action.payload.nearbyArrivals,
        favoriteArrivals: action.payload.favorites,
        arrivalsTimestamp: Date.now(),
        isArrivalsError: false,
      };
    },
    fetchArrivalsError(state) {
      return {
        ...state,
        nearbyArrivals: [],
        favoriteArrivals: [],
        arrivalsTimestamp: null,
        isArrivalsError: true,
      };
    },
    fetchLocationArrivalsSuccess(state, action) {
      return {
        ...state,
        nearbyLocationArrivals: action.payload.nearby,
        arrivalsTimestamp: Date.now(),
        isArrivalsError: false,
      };
    },
    clearLocationArrivals(state) {
      return {
        ...state,
        nearbyLocationArrivals: null,
      };
    },
    resetUserArrivals(state) {
      return {
        ...state,
        nearbyArrivals: null,
      };
    },
    setUserArrivalsEmpty(state) {
      return {
        ...state,
        nearbyArrivals: [],
      };
    },
    setLocationArrivalsEmpty(state) {
      return {
        ...state,
        nearbyLocationArrivals: [],
      };
    },
    setHighlightedStop(state, action) {
      state.highlightedStop = action.payload;
    },
    setFocusedOnHighlightedStop(state, action) {
      state.focusedOnHighlightedStop = action.payload;
    },
    fetchVehicleRouteSuccess(state, action) {
      return {
        ...state,
        vehicleRouteTimestamp: Date.now(),
        vehicleRoute: action.payload,
        vehicleRouteLoading: false,
        isArrivalsError: false,
      };
    },
    fetchVehicleRouteEmpty(state) {
      return {
        ...state,
        vehicleRoute: null,
        vehicleRouteLoading: false,
      };
    },
    vehicleRouteCleared(state) {
      return {
        ...state,
        vehicleRoute: null,
        vehicleRouteLoading: false,
      };
    },
    favoriteStopsChanged(state, action) {
      state.favoriteStops = action.payload;
    },
    setTrackedVehicle(state, action) {
      return {
        ...state,
        trackedVehicle: action.payload,
      };
    },
    clearTrackedVehicle(state) {
      return {
        ...state,
        trackedVehicle: null,
      };
    },
  },
});

export const {
  trackedVehicleCenterMap,
  resetUserArrivals,
  fetchArrivalsError,
  fetchArrivalsSuccess,
  fetchLocationArrivalsSuccess,
  setLocationArrivalsEmpty,
  clearLocationArrivals,
  setUserArrivalsEmpty,
  setHighlightedStop,
  setFocusedOnHighlightedStop,
  fetchVehicleRouteSuccess,
  fetchVehicleRouteEmpty,
  vehicleRouteCleared,
  favoriteStopsChanged,
  setTrackedVehicle,
  clearTrackedVehicle,
} = transitTrackerSlice.actions;

export const selectTrackedVehicle = state =>
  state.transitTracker.trackedVehicle;

export default transitTrackerSlice.reducer;
