import { STORAGE_PREFIX } from '#/shared/constants';

interface StorageOptions {
  /** If `true` sessionStorage will be used otherwise localStorage */
  session?: boolean;
}

interface WriteStorageOptions extends StorageOptions {
  errorCallback?: (() => void) | null;
}

const getStorageObj = (session: boolean) => {
  // browser storage objects aren't available during SSR
  if (typeof window === 'undefined') return null;

  return session ? sessionStorage : localStorage;
};

export function storeItem(
  key: string,
  object: unknown,
  { session = false, errorCallback = null }: WriteStorageOptions = {}
): void {
  const storage = getStorageObj(session);
  if (!storage) return;

  try {
    storage.setItem(`${STORAGE_PREFIX}.${key}`, JSON.stringify(object));
  } catch (e) {
    console.warn(
      'The trimet.org app can not save user preferences at this time. Local/Session Storage may be disabled for this site. Please re-enable this feature for the full user experience.'
    );
    if (errorCallback) errorCallback();
  }
}

export function getItem<T>(
  key: string,
  notFoundValue: T = null as T,
  { session = false }: StorageOptions = {}
): T {
  let item = notFoundValue;
  const storage = getStorageObj(session);
  if (!storage) return item;

  try {
    const itemAsString = storage.getItem(`${STORAGE_PREFIX}.${key}`);
    if (itemAsString === 'false') {
      item = false as T;
    } else if (itemAsString === 'true') {
      item = true as T;
    } else {
      const json = JSON.parse(itemAsString || 'null');
      if (json !== null) {
        return json as T;
      }
    }
  } catch (e) {
    item = notFoundValue;
  }
  return item;
}

export function removeItem(
  key: string,
  { session = false }: StorageOptions = {}
): void {
  const storage = getStorageObj(session);
  if (!storage) return;

  storage.removeItem(`${STORAGE_PREFIX}.${key}`);
}
